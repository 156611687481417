export const fakeModalScreens = [
  "/",
  "/forgotpassword",
  "/resetpassword",
  "/groupaddedit",
  "/memberaddedit",
  "/consoleuseradd",
  "/useraddedit",
  "/consoleaddedit",
  "/controlleraddedit",
  "/videocall/iceservers/addedit",
  "/account/changepassword",
  "/account/setup2fa",
  "/account/disable2fa",
]


export const navigationlinks = {
  superadmin: [
    {
      name: "Groups",
      url: "/groups",
    },
    {
      name: "Users",
      url: "/users",
    },
    {
      name: "Devices",
      url: "/devices",
    },
    // {
    //   name: "Controllers",
    //   url: "/controllers",
    // },
    {
      name: "Audit Logs",
      url: "/auditlogs",
    },
    {
      name: "Video Call",
      children: [
        {
          name: "Ice Servers",
          url: "/videocall/iceservers",
        },
      ],
    },
    {
      name: "Account",
      url: "/account",
    },
  ],
}
